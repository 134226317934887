import React from 'react'
import { Link, Trans } from 'gatsby-plugin-react-i18next'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Grid, Item, Title } from './styles'

const CategoriesGrid = ({ categories }) => (
  <Grid>
    {categories.map(({ handle, id, title, productsCount, image }) => (
      <Link
        key={id}
        to={`/shop/category/${handle}/`}
        style={{ textDecoration: 'none' }}
      >
        <Item>
          <GatsbyImage
            image={image.localFile.childImageSharp.gatsbyImageData}
            alt={title}
          />
          <Title>
            <Trans>{title}</Trans>
            {` `}({productsCount})
          </Title>
        </Item>
      </Link>
    ))}
  </Grid>
)

export default CategoriesGrid
