import React from 'react'
import { graphql } from 'gatsby'

import Page from '../templates/Page'
import CategoriesGrid from '../components/CategoriesGrid'
import { Spacer } from '../utils/styles'
import { transformTranslation } from '../utils/helpers'

const ShopPage = ({
  data: { heroImage, categories, translatedCategories },
}) => {
  const tCategories = transformTranslation(categories, translatedCategories)

  console.log(translatedCategories)

  return (
    <Page title="Shop" headerImage={heroImage}>
      <CategoriesGrid categories={tCategories} />
      <Spacer height="5rem" />
    </Page>
  )
}

export const query = graphql`
  query ($language: String!) {
    translatedCategories: allShopifyTranslatedCollection(
      filter: { locale: { eq: $language } }
    ) {
      edges {
        node {
          title
          description
          descriptionHtml
          locale
          storefrontId
        }
      }
    }
    categories: allShopifyCollection(filter: { title: { ne: "Frontpage" } }) {
      edges {
        node {
          id
          handle
          title
          storefrontId
          productsCount
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                  layout: FULL_WIDTH
                  aspectRatio: 1
                )
              }
            }
          }
        }
      }
    }
    heroImage: file(
      name: { eq: "service_header" }
      sourceInstanceName: { eq: "images" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
          aspectRatio: 4
        )
      }
    }
    i18nLocales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ShopPage
