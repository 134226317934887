import { find } from 'lodash'

import vatRates from '../images/vat-rates.json'

export const transformTranslation = (products, translatedProducts) =>
  products.edges.map(({ node }) => ({
    ...node,
    ...find(translatedProducts.edges, {
      node: { storefrontId: node.storefrontId },
    })?.node,
  }))

export const calcuateGrossPrice = (netPrice, countryCode) => {
  const rate = vatRates[countryCode] || 1
  return (netPrice * rate).toFixed(2)
}

export const toggleNoScroll = () =>
  document.body.classList.toggle('body-noscroll-class')
