import styled from '@emotion/styled'

import { breakpoints, colors } from '../../utils/styles'

export const Grid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${breakpoints.s}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const Item = styled.div`
  border: 1px solid lightgrey;
  height: 100%;
`

export const Title = styled.h2`
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 0;
  color: ${colors.androidGreen};
`
